import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Accordion from '../../../components/Accordion.js'
import Layout from '../../../components/layout'

const BiharAll = ({data}) => {
    return <Layout>
        <Helmet>
            <title>Bihari Pandit in Bangalore (15+yrs Exp): Book Maithili Pandit in Bangalore & Mithila Pandit in Bangalore for all types of Pujas</title>
            <meta name="description" content="Looking for Bihari Pandit in Bangalore, India for Puja? We provide the best Maithili Pandit in Bangalore, Mithila Pandit in Bangalore and Vedic purohits for Sunderkhand Path, Hartilika Teej, Ramayana Path and all types of Poojas." />
            <script type="application/ld+json">{`
                 {
                  "@context":"https://schema.org",
                  "@type":"FAQPage",
                  "mainEntity": [
                    {
                      "@type":"Question",
                      "name":"Should the customer arrange for Puja Samagri or would the Panditji arrange?",
                      "acceptedAnswer": 
                      {
                        "@type":"Answer",
                        "text":"Customers have the flexibility to book the Puja along with Samagri or book only Panditji for the Puja"
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Do multiple Pandits call the customer at once upon enquiry, causing them inconvenience?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"No. Namaste God values your time and comfort, our team after understanding your requirements arranges a qualified Panditji for the call. In case the customer not satisfied with the Panditji, our team will connect you with another Panditji."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Will the Panditji help the customer in choosing good muhurth for ritual?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"Yes, our Panditji upon connecting with the customer, will refer to the Panchang and provide an appropriate date and time for the ritual to be performed."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Would the Puja be performed as per the Bihari traditions?",
                      "acceptedAnswer":
                      {  
                        "@type":"Answer",
                        "text":"Namaste God provides a wide variety of qualified and experienced panditjis who are hailing from Bihar. Our team would connect you with the appropriate Panditji upon your requirement and you can discuss about the vidi and vidhan prior to the Puja being booked."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"How can the customers pay? Through cash or Wallet or Netbanking?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay) or e-Wallet (PayTM)"
                      }
                    }
                  ]
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "ImageObject",
                  "author": "NamasteGod",
                  "name": "${data.allMarkdownRemark.edges[1].node.frontmatter.title}",
                  "contentUrl": "https://www.namastegod.com${data.allFile.nodes[1].childImageSharp.fluid.src}",
                  "contentLocation": "Bangalore, India"
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "Product",
                  "name": "Bihari Pandit in Bangalore",
                  "image": "https://www.namastegod.com${data.allFile.nodes[1].childImageSharp.fluid.src}",
                  "description": "Looking for Bihari Pandit in Bangalore, India for Puja? We provide the best Maithili Pandit Ji and Vedic Purohits for Sunderkhand Path, Hartilika Teej, Ramayana Path and all types of Poojas.",
                  "sku": "0000000002",
                  "mpn": "000002",
                  "brand": {
                    "@type": "Brand",
                    "name": "NamasteGod"
                  },
                  "review": {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5",
                      "bestRating": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Rekha Anoop"
                    }
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "reviewCount": "101"
                  },
                  "offers": {
                    "@type": "Offer",
                    "url": "https://www.namastegod.com/findonline/hindu/bihari-pandit-in-bangalore-india-for-puja-marriage-wedding-mithila-purohits-for-vivah/",
                    "priceCurrency": "INR",
                    "price": "3100",
                    "priceValidUntil": "2020-12-31",
                    "itemCondition": "https://schema.org/UsedCondition",
                    "availability": "https://schema.org/InStock",
                    "seller": {
                      "@type": "Organization",
                      "name": "NamasteGod"
                    }
                  }
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "LocalBusiness",
                  "name": "Bihari Pandit in Bangalore",
                  "telephone": "(+91) 81972 49957",
                  "priceRange": "₹₹₹",
                  "url": "https://www.namastegod.com/findonline/hindu/bihari-pandit-in-bangalore-india-for-puja-marriage-wedding-mithila-purohits-for-vivah/",
                  "image": "https://www.namastegod.com${data.allFile.nodes[1].childImageSharp.fluid.src}",
                  "address":
                  {
                    "@type": "PostalAddress",
                    "addressLocality": "Bangalore",
                    "addressRegion": "KA",
                    "addressCountry": "IN",
                    "postalCode": "560078",
                    "streetAddress": "#142/1, 17th Cross, 18th Main, JP Nagar 5th Phase"
                   }
                }
            `}</script> 
        </Helmet>
        <div id="main" className="pandit-listing">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1 className="desktop">Bihari Pandit in Bangalore (15+yrs Exp):<br/>Book Maithili Pandit Ji for all types of Pujas</h1>
                        <h1 className="mobile">Bihari Pandit in Bangalore<br/>(15+yrs Exp):Book Maithili Pandit Ji<br/>for all Pujas</h1>
                    </header>
                    <a href="https://g.co/kgs/MciV3q">
                        <span className="reviews">
                            <i class="fa fa-star" aria-hidden="true"></i>
                            <i class="fa fa-star" aria-hidden="true"></i>
                            <i class="fa fa-star" aria-hidden="true"></i>
                            <i class="fa fa-star" aria-hidden="true"></i>
                            <i class="fa fa-star" aria-hidden="true"></i>
                            <span>Reviews</span>
                        </span>
                    </a>
                    <p>Looking for Bihari Pandit in Bangalore, India for Puja? We provide the best Maithili Pandit Ji and Vedic <a href='https://en.wikipedia.org/wiki/Purohit' className='no-highlight'>Purohits</a> for Sunderkhand Path, Hartilika Teej, Ramayana Path and all types of Poojas.</p>
                    <Link className="button special" to="#contact">Contact Us</Link>
                    <Accordion
          id="1"
          question=" more"
          answer="<h2>Top 5 Bihari Pandits in Bangalore</h2>
          <table>
            <tbody>
                <tr>
                    <th>Name</th><th>Region</th>
                    <th>Yrs of Exp</th><th>Pujas Done</th>
                </tr>
                <tr>
                    <td>Pandit Subodh Pandey</td>
                    <td>Bihar, UP, Odisha, Bengal</td><td>15+</td>
                    <td>3000+</td>
                </tr>
                <tr>
                    <td>Pandit Ashutosh Pandey</td><td>Bihar, UP, Jharkhand</td>
                    <td>10+</td><td>2500+</td>
                </tr>
                <tr>
                    <td>Pandit Sudhakar Pandey</td>
                    <td>Bihar, UP, Jharkhand</td>
                    <td>10+</td>
                    <td>2000+</td>
                </tr>
                <tr>
                    <td>Pandit Prashant Pandey</td>
                    <td>Bihar, UP, Bengal</td>
                    <td>13+</td>
                    <td>3000+</td>
                </tr>
                <tr>
                    <td>Pandit Hem Chandra Joshi</td>
                    <td>Bihar, Kumaoni, Garhwali, Uttrakhand, UP</td>
                    <td>12+</td>
                    <td>3000+</td>
                </tr>
            </tbody>
        </table>
        <h3>Frequently Asked Questions</h3>
<ol>
<li class='bold'>Should the customer arrange for Puja Samagri or would the Panditji arrange?
<span>Customers have the flexibility to book the Puja along with Samagri or book only Panditji for the Puja.</span></li>
<li class='bold'>Do multiple Pandits call the customer at once upon enquiry, causing them inconvenience?
<span>No. Namaste God values your time and comfort, our team after understanding your requirements arranges a qualified Panditji for the call. In case the customer not satisfied with the Panditji, our team will connect you with another Panditji.</span></li>
<li class='bold'>Will the Panditji help the customer in choosing good muhurth for ritual?
<span>Yes, our Panditji upon connecting with the customer, will refer to the Panchang and provide an appropriate date and time for the ritual to be performed.</span></li>
<li class='bold'>Would the Puja be performed as per the Bihari traditions?
<span>Namaste God provides a wide variety of qualified and experienced panditjis who are hailing from Bihar. Our team would connect you with the appropriate Panditji upon your requirement and you can discuss about the vidi and vidhan prior to the Puja being booked.</span></li>
<li class='bold'>How can the customers pay? Through cash or Wallet or Netbanking?
<span>Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay) or e-Wallet (PayTM)</span></li>
</ol>

<p>
These are the Bihari Pandit specific services we provide:</br>

<strong>Satyanarayan pooja in Bihari style:</strong></br>

One of the most prominent deity that is worshipped during satyanarayan puja is Lord Vishnu. Satyanarayana puja is normally performed throughout the year for the well being of family members. We provide Bihari Pandit in Bangalore and Maithili Pandit in Bangalore satyanarayan puja service at a click of a button!</br></br>

<strong>Griha Pravesh puja in Bihari style:</strong></br>

One of the most important god that we take blessing during Griha Pravesh puja is Lord Ganesh. Griha Pravesh puja is usually done as per the panchang for the good will of well wishers and friends. We provide Bihari Pandit in Bangalore and Mithila Pandit in Bangalore Griha Pravesh puja service at a click of a button!</br></br>

<strong>Namkaran ceremony in Bihari style:</strong></br>

One of the most popular almighty that is prayed during Namkaran ceremony is Goddess Lakshmi. Namkaran ceremony is as a practice done on an auspicious day for the better future of closer ones. We provide Bihari Pandit in Bangalore and verified Bihari Pandit Ji in Bangalore Namkaran ceremony service at a click of a button!</br></br>

<strong>Marriage ceremony in Bihari style:</strong></br>

One of the most prominent deity that is worshipped during Marriage Ceremony is Lord Ganapathi. Marriage Ceremony is normally performed through out the year for the well being of family members. We provide Bihari Pandit in Bangalore and Maithili Pandit in Bangalore Marriage Ceremony service at a click of a button!</br></br>

<strong>Annaprashan puja in Bihari style:</strong></br>

One of the most important god that we take blessing during Annaprashan puja is Lord Krishna. Annaprashan puja is usually done as per the panchang for the good will of well wishers and friends. We provide Bihari Pandit in Bangalore and Mithila Pandit in Bangalore Annaprashan puja service at a click of a button!</br></br>

<strong>Ayusha havan in Bihari style:</strong></br>

One of the most popular almighty that is prayed during Ayusha havan is God mahadev. Ayusha havan is as a practice done on an auspicious day for the better future of closer ones. We provide Bihari Pandit in Bangalore and verified Bihari Pandit Ji in Bangalore Ayusha havan service at a click of a button!</br></br>

<strong>Bhoomi puja in Bihari style:</strong></br>

One of the most prominent deity that is worshipped during Bhoomi puja is goddess earth or nature. Bhoomi puja is normally performed through out the year for the well being of family members. We provide Bihari Pandit in Bangalore and Maithili Pandit in Bangalore Bhoomi puja service at a click of a button!</br></br>

<strong>Chandi Havan in Bihari style:</strong></br>

One of the most important god that we take blessing during Chandi Havan is goddess chandi. Chandi Havan is usually done as per the panchang for the good will of well wishers and friends. We provide Bihari Pandit in Bangalore and Mithila Pandit in Bangalore Chandi Havan service at a click of a button!</br></br>

<strong>Lakshmi Puja in Bihari style:</strong></br>

One of the most popular almighty that is prayed during Lakshmi Puja is Goddess lakshmi devi. Lakshmi Puja is as a practice done on an auspicious day for the better future of closer ones. We provide Bihari Pandit in Bangalore and verified Bihari Pandit Ji in Bangalore Lakshmi Puja service at a click of a button!</br></br>

<strong>Dhanvantari Havan in Bihari style:</strong></br>

One of the most prominent deity that is worshipped during Dhanvantari Havan is god of heath that is dhanvantari. Dhanvantari Havan is normally performed through out the year for the well being of family members. We provide Bihari Pandit in Bangalore and Maithili Pandit in Bangalore Dhanvantari Havan service at a click of a button!</br></br>

<strong>Engagement Ceremony in Bihari style:</strong></br>

One of the most important god that we take blessing during Engagement Ceremony is Lord Shiva. Engagement Ceremony is usually done as per the panchang for the good will of well wishers and friends. We provide Bihari Pandit in Bangalore and Mithila Pandit in Bangalore Engagement Ceremony service at a click of a button!</br></br>

<strong>Janmashtami puja in Bihari style:</strong></br>

One of the most popular almighty that is prayed during Janmashtami puja is Lord Krishna. Janmashtami puja is as a practice done on an auspicious day for the better future of closer ones. We provide Bihari Pandit in Bangalore and verified Bihari Pandit Ji in Bangalore Janmashtami puja service at a click of a button!</br></br>

<strong>Navagraha Puja in Bihari style:</strong></br>

One of the most prominent deity that is worshipped during Navagraha Puja is 9 gods that is navagarha. Navagraha Puja is normally performed through out the year for the well being of family members. We provide Bihari Pandit in Bangalore and Maithili Pandit in Bangalore Navagraha Puja service at a click of a button!</br></br>

<strong>Mrityunjaya Havan in Bihari style:</strong></br>

One of the most important god that we take blessing during Mrityunjaya Havan is Lord Mrityunjaya. Mrityunjaya Havan is usually done as per the panchang for the good will of well wishers and friends. We provide Bihari Pandit in Bangalore and Mithila Pandit in Bangalore Mrityunjaya Havan service at a click of a button!</br></br>

<strong>Navaratri Puja in Bihari style:</strong></br>

One of the most popular almighty that is prayed during Navaratri Puja is goddess durga maa. Navaratri Puja is as a practice done on an auspicious day for the better future of closer ones. We provide Bihari Pandit in Bangalore and verified Bihari Pandit Ji in Bangalore Navaratri Puja service at a click of a button!</br></br>

<strong>Office opening puja in Bihari style:</strong></br>

One of the most prominent deity that is worshipped during Office opening puja is goddess of prosperity lakshmi. Office opening puja is normally performed through out the year for the well being of family members. We provide Bihari Pandit in Bangalore and Maithili Pandit in Bangalore Office opening puja service at a click of a button!</br></br>

<strong>Saraswati puja in Bihari style:</strong></br>

One of the most important god that we take blessing during Saraswati puja is goddess sarawathi maa. Saraswati puja is usually done as per the panchang for the good will of well wishers and friends. We provide Bihari Pandit in Bangalore and Mithila Pandit in Bangalore Saraswati puja service at a click of a button!</br></br>

<strong>Rudrabhishek puja in Bihari style:</strong></br>

One of the most popular almighty that is prayed during Rudrabhishek puja is god shamboo shankara. Rudrabhishek puja is as a practice done on an auspicious day for the better future of closer ones. We provide Bihari Pandit in Bangalore and verified Bihari Pandit Ji in Bangalore Rudrabhishek puja service at a click of a button!</br></br>

<strong>Gowri puja in Bihari style:</strong></br>

One of the most prominent deity that is worshipped during Gowri puja is goddess gowri maa. Gowri puja is normally performed through out the year for the well being of family members. We provide Bihari Pandit in Bangalore and Maithili Pandit in Bangalore Gowri puja service at a click of a button!</br></br>

<strong>Hartilika Teej puja in Bihari style:</strong></br>

One of the most important god that we take blessing during Hartilika Teej puja is goddess parvathi maa. Hartilika Teej puja is usually done as per the panchang for the good will of well wishers and friends. We provide Bihari Pandit in Bangalore and Mithila Pandit in Bangalore Hartilika Teej puja service at a click of a button!</br></br>

<strong>Sunderkhand path in Bihari style:</strong></br>

One of the most popular almighty that is prayed during Sunderkhand path is god vishnu deva. Sunderkhand path is as a practice done on an auspicious day for the better future of closer ones. We provide Bihari Pandit in Bangalore and verified Bihari Pandit Ji in Bangalore Sunderkhand path service at a click of a button!</br></br>

<strong>Shradh ceremony in Bihari style:</strong></br>

One of the most prominent deity that is worshipped during Shradh ceremony is ancestor soul. Shradh ceremony is normally performed throughout the year for the satisfying ancestor soul. We provide Bihari Pandit in Bangalore and Maithili Pandit in Bangalore Shradh ceremony service at a click of a button!</br></br>

<strong>Last Rites Ceremony in Bihari style:</strong></br>

One of the most important god that we take blessing during Last Rites Ceremony is god shiva. Last Rites Ceremony is usually done as per the panchang for the moksha of the deceased ancestor. We provide Bihari Pandit in Bangalore and Mithila Pandit in Bangalore Last Rites Ceremony service at a click of a button!
</p>

"
        />
                </div>
            </section>
            <section id="two" className="spotlights">
                {data.allFile.nodes.map((value, index) => {
                    return <section>
                        <div className="image">
                            <img src={value.childImageSharp.fluid.src} alt={data.allMarkdownRemark.edges[index].node.frontmatter.title} title={data.allMarkdownRemark.edges[index].node.frontmatter.title}/>
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>{data.allMarkdownRemark.edges[index].node.frontmatter.title_hindi}</h3>
                                </header>
                                <div className="details">
                                    <span className="label">Years of Experience: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.experience}</span><br/>
                                    <span className="label">Completed his Degree: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.degree}</span><br/>
                                    <span className="label">Well Versed in Puja Traditions as per Regions: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.regions}</span><br/>
                                    <span className="label">In Bangalore since: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.bangalore}</span><br/>
                                    <span className="label">Specialized In: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.specialized}</span><br/>
                                    <span className="label">Puja’s Performed: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.pujas}</span>
                                </div>
                            </div>
                        </div>
                    </section>
                })}
            </section>
        </div>

    </Layout>
}

export const query = graphql`
{
  allMarkdownRemark(sort: {fields: frontmatter___order_bihar}) {
    edges {
      node {
        frontmatter {
          order_bihar
          title
          title_hindi
          experience
          degree
          regions
          bangalore
          specialized
          pujas
        }
      }
    }
  }
  allFile(filter: {name: {in: ["Pandit B4Hem Chandra Joshi", "Pandit B5TPrashanth Pandey", "Pandit B2Subodh Pandey", "Pandit B1Ashutosh Pandey", "Pandit B3TSudharkar Pandey", "Pandit B6UDiwakar Pandey"]}} , sort: {fields: name}) {
    nodes {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}`

export default BiharAll